<template>
  <div class="solution1">
    <div class="page-1">
      <div class="flex-col  p-a">
        <h1 class="m0 w-100">精细化运营解决方案</h1>
        <p>
          <span>大数据 + 行业领先的运营工具，让流量运营更精准、科学、高效</span>
        </p>
        <div>
          <my-button name="免费使用" @click.stop="LogInClick('login')" />
        </div>
      </div>
    </div>
    <div class="page-2 flex-col align-center">
      <h2 class="m0">多源数据汇聚形成数据资产，赋能业务发展</h2>
      <div class="solution1-image2"></div>
    </div>
    <div class="page-3 flex-row flex-center">
      <div class="solution1-image3"></div>
      <div class="solution1-txt3">
        <h3>高效的流量分组，助力精准营销</h3>
        <p>
          <span>
            支持多维度条件筛选组合，自由、灵活、快速地创建流量分组，结
          </span>
          <br />
          <span>合多样化的数据分析工具，让精准营销不再是难事 </span>
        </p>
        <my-button name="免费使用" />
      </div>
    </div>
    <div class="page-4 flex-row flex-center j-space-around ">
      <div class="solution1-txt4">
        <h3 class="m0">科学的A/B测试，驱动收益增长</h3>
        <p>
          <span>
            支持流量互斥和流量正交，高效复用流量，帮助产品运营人员通过
          </span>
          <br />
          <span>实验选取最佳策略，实现收益的增长</span>
        </p>
        <my-button name="免费使用" @click.stop="LogInClick('login')" />
      </div>
      <div class="solution1-image4"></div>
    </div>
  </div>
</template>

<script lang="ts">
import MyButton from "@/components/MyButton.vue";
import { defineComponent } from "vue";
import LogInClick from "../use/LogInClick";

export default defineComponent({
  components: { MyButton },
  setup() {
    const data = [
      {
        head: "SDK接入",
        txt: "轻量化的SDK，便捷的接入方式，极大降低广告接入门槛",
        url: "",
      },
      {
        head: "聚合管理",
        txt: "轻松管理多平台的应用、广告位和广告源，让你的广告资源一目了然",
        url: "",
      },
      {
        head: "智能策略",
        txt: "系统为您自动优化广告，降低运营成本，促进收益增长",
        url: "",
      },
    ];
    return {
      data,
      LogInClick,
    };
  },
});
</script>

<style lang="scss" scoped>
.solution1 {
  width: 100%; ///;
  font-family: PingFangSC-Semibold, PingFang SC;
  // font-weight: 600;

  .page-1 {
    width: 100%; ///;
    height: 31.25vw;
    background-color: #eff0f5;
    background-image: url(~@/assets/image/high/high2_photo1.png),
      url(~@/assets/image/high/high2_bg1.png);
    background-size: 24.583vw 24.583vw, auto 100%;
    background-position: top 70% right 10vw, left top;
    background-repeat: no-repeat;
    div {
      left: 14.323vw;
      top: 11.094vw;
      h1 {
        font-size: 2.5vw;
        color: #000;
        line-height: 4.375vw;
      }
      p {
        font-size: 1.25vw;
        color: #000;
        line-height: 1.719vw;
        margin: 1.25vw 0 2vw;
      }
    }
  }
  .page-2 {
    height: 41.667vw;
    h2 {
      margin: 3.281vw 0 1.8vw;
      color: #000;
      font-size: 1.667vw;
    }
    background-image: url(~@/assets/image/high/high2_bg2.png);
    background-repeat: no-repeat;
    background-size: 100%;
    .solution1-image2 {
      width: 70.313vw;
      height: 29.583vw;
      background-image: url(~@/assets/image/high/high2_photo2.png);
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }
  .page-3 {
    height: 41.979vw;
    background: linear-gradient(169deg, #142d67 0%, #000000 100%);
    color: #fff;
    .solution1-image3 {
      width: 32.448vw;
      height: 27.292vw;
      margin-left: 6.333vw;
      background-image: url(~@/assets/image/high/high2_phopto3.png);
      background-repeat: no-repeat;
      background-size: 100%;
    }
    .solution1-txt3 {
      margin: 0 14.271vw;
      h3 {
        font-size: 1.667vw;
      }
      p {
        font-size: 0.833vw;
        margin: 1.25vw 0 2.5vw;
      }
    }
  }
  .page-4 {
    height: 41.667vw;
    // background: linear-gradient(169deg, #142d67 0%, #000000 100%);
    background: linear-gradient(
      181deg,
      rgba(223, 235, 242, 0.5) 0%,
      #ffffff 100%
    );
    color: #000;
    .solution1-image4 {
      width: 32.396vw;
      height: 27.5vw;
      background-image: url(~@/assets/image/high/high2_photo4.png);
      background-repeat: no-repeat;
      background-size: 100%;
    }
    .solution1-txt4 {
      // margin: 0 14.271vw;
      h3 {
        font-size: 1.667vw;
      }
      p {
        font-size: 0.833vw;
        margin: 1.25vw 0 2.5vw;
      }
    }
  }
}
</style>
