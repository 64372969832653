<template>
  <div class="bottom-box flex-row flex-center ">
    {{ name }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    name: {
      type: String,
      default: "按钮",
    },
  },
  //   setup() {},
});
</script>

<style lang="scss" scoped>
.bottom-box {
  color: #fff;
  padding: 0.48vw 2.083vw;
  cursor: pointer;
  display: inline-block;
  height: 1.146vw;
  line-height: 1.146vw;
  font-size: 0.833vw;
  background: #5ca4f1;
  box-shadow: 0vw 1.042vw 1.042vw -0.938vw #5ca4f1,
    0.052vw 0.052vw 0vw 0vw #a1ceff, -0.052vw -0.052vw 0vw 0vw #3c8add;
  border-radius: 4px;
  font-weight: 600;
  &:active {
    box-shadow: none;
  }
}
</style>
